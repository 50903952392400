<!--停电及故障电损统计-->
<template>
    <widget-framework v-if="dataLoaded" title="停电及故障电损统计">
        <template #content>
            <div class="container">
                <div class="left-container">
                    <div class="pieChart">
                        <v-chart :options="pieOpt" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
                    </div>
                    <div class="dv-box-container">
                        <dv-border-box-8 class="dv-border-box">
                            <div class="dv-box">
                                <span class="value">{{totalPlannedHours}}(小时)</span>
                                <span class="title">计划内停电时间</span>
                            </div>
                        </dv-border-box-8>
                        <dv-border-box-8 class="dv-border-box">
                            <div class="dv-box">
                                <span class="value">{{totalUnplannedHours}}(小时)</span>
                                <span  class="title">非计划停电时间</span>
                            </div>
                        </dv-border-box-8>
                    </div>
                </div>
                <div class="right-container">
                    <v-chart :options="barOpt" :autoresize="true" style="height:100%;width:100%"></v-chart>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import {
    blue,
    cyan,
    filledCircle,
    grey,
    lightGrey, red,
    tooltip,
    white, whiteOpacity,
    yellow,
} from '@/components/widgets/constant';
import dayjs from 'dayjs';
import EchartBase from '@/components/widgets/EchartBase';

export default {
    name: 'PowerLossAndFailure',
    components: {
        WidgetFramework
    },
    mixins: [EchartBase],
    data() {
        return {
            dataLoaded: false,
            totalPlannedHours: 0,
            totalUnplannedHours: 0,
            pieOpt: {
                textStyle: {
                    color: lightGrey
                },
                grid: {
                    top: 100
                },
                tooltip: {
                    trigger: 'item',
                    position: this.setTooltipPosition,
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText
                },
                dataset: [],
                series: [
                    {
                        name: '停电次数',
                        type: 'pie',
                        radius: [0, '60%'],
                        hoverAnimation: false,
                        itemStyle: {
                            color: filledCircle
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `{a|${value}}\n{b|${name}}`;
                            },
                            rich: {
                                a: {
                                    color: yellow,
                                    fontSize: 20,
                                    height: 30,
                                    fontWeight: 'bold'
                                },
                                b: {
                                    color: white,
                                    fontSize: 10
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                        },
                        datasetIndex: 0,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}`;
                            }
                        }
                    },
                    {
                        name: '停电情况',
                        type: 'pie',
                        radius: ['50%', '65%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                if (name === '非计划停电') {
                                    return `{a|${value}(次)}\n{b|${name}}`;
                                }
                                return `{c|${value}(次)}\n{b|${name}}`;
                            },
                            alignTo: 'labelLine',
                            rich: {
                                a: {
                                    color: blue,
                                    fontSize: '18px',
                                    lineHeight: 15
                                },
                                b: {
                                    color: white,
                                    fontSize: '18px',
                                    lineHeight: 15
                                },
                                c: {
                                    color: yellow,
                                    fontSize: '18px',
                                    lineHeight: 15
                                },
                            }
                        },
                        labelLine: {
                            show: true,
                            length2: 40,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        datasetIndex: 1,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}次`;
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['75%', '80%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    },
                    {
                        type: 'pie',
                        radius: ['85%', '93%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    }
                ]
            },
            barOpt: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'cross',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2
                        }
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter: (params) => {
                        const plannedDetails = params.filter(item => item.seriesName === '计划内停电' && item.value !== undefined) || [];
                        const plannedHours = plannedDetails.reduce((total = 0, cur) => {
                            return total + cur.value;
                        }, 0);
                        const unplannedDetails = params.filter(item => item.seriesName === '非计划停电' && item.value !== undefined) || [];
                        const unplannedHours = unplannedDetails.reduce((total = 0, cur) => {
                            return total + cur.value;
                        }, 0);
                        const month = params[0].axisValue;
                        const monthStr = dayjs(month).format('YYYY年M月');
                        return `${monthStr}<br/>
                                    计划内停电：${plannedDetails.length}次，共${plannedHours}小时<br/>
                                    非计划停电：${unplannedDetails.length}次，共${unplannedHours}小时<br/>`;
                    }
                },
                legend: {
                    // data: ['计划内停电', '非计划停电', '停电次数'],
                    data: ['计划内停电', '非计划停电'],
                    top: 'bottom',
                    textStyle: {
                        color: white,
                        fontSize: 14
                    }
                },
                grid: {
                    top: '30',
                    left: '30',
                    right: '0',
                    bottom: '30',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            formatter: (value) => {
                                return dayjs(value).format('YY/MM');
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: grey
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位：小时',
                        axisLine: {
                            lineStyle: {
                                color: grey
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    /*{
                        type: 'value',
                        name: '单位：次数',
                        axisLabel: {
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: grey
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }*/
                ]
            }
        };
    },
    mounted() {
        this.$client.getPowerCutInfo().then((ret) => {
            const dataset = [];
            const dataset1 = [];
            let totalTimes = 0;
            let totalPlannedTimes = 0;
            let totalUnplannedTimes = 0;
            let totalPlannedHours = 0;
            let totalUnplannedHours = 0;

            const {data = []} = ret;
            const xData = [];
            const plannedSeries = [];
            const unplannedSeries = [];
            // const timesData = [];

            data.forEach((item, index) => {
                const {month} = item;
                const plannedDetails = item.plannedDetails || [];
                const unplannedDetails = item.unplannedDetails || [];

                // timesData.push(plannedDetails.length + unplannedDetails.length);
                totalTimes = totalTimes + plannedDetails.length + unplannedDetails.length;
                totalPlannedTimes += plannedDetails.length;
                totalUnplannedTimes += unplannedDetails.length;

                xData.push(month);
                plannedDetails.forEach((plannedDetail, childIndex) => {
                    totalPlannedHours += plannedDetail.hours;
                    if (childIndex >= plannedSeries.length) {
                        const data = [];
                        data[index] = {value: plannedDetail.hours, item: plannedDetail};
                        plannedSeries.push({
                            name: '计划内停电',
                            stack: '计划内停电',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: blue
                                }
                            },
                            data
                        });
                    } else {
                        plannedSeries[childIndex].data[index] = {value: plannedDetail.hours, item: plannedDetail};
                    }
                });
                unplannedDetails.forEach((unplannedDetail, childIndex) => {
                    totalUnplannedHours += unplannedDetail.hours;
                    if (childIndex >= unplannedSeries.length) {
                        const data = [];
                        data[index] = {value: unplannedDetail.hours, item: unplannedDetail};
                        unplannedSeries.push({
                            name: '非计划停电',
                            stack: '非计划停电',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: cyan
                                }
                            },
                            data
                        });
                    } else {
                        unplannedSeries[childIndex].data[index] = {value: unplannedDetail.hours, item: unplannedDetail};
                    }
                });
            });

            dataset1.push({name: '计划停电', value: totalPlannedTimes, itemStyle: {color: yellow}});
            dataset1.push({name: '非计划停电', value: totalUnplannedTimes, itemStyle: {color: blue}});
            dataset.push({source: [{name: '停电次数', value: totalTimes}]});
            dataset.push({source: dataset1});
            this.pieOpt.dataset = dataset;
            this.totalPlannedHours = totalPlannedHours;
            this.totalUnplannedHours = totalUnplannedHours;

            /*this.barOpt.series = [...plannedSeries, ...unplannedSeries, {
                name: '停电次数',
                type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    type: 'dashed',
                    color: red
                },
                itemStyle: {
                    color: red
                },
                yAxisIndex: 1,
                data: timesData
            }];*/
            this.barOpt.series = [...plannedSeries, ...unplannedSeries];
            this.barOpt.xAxis[0].data = xData;
            this.dataLoaded = true;
        });
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.container {
    display: flex;
    width: 100%;
    height: 100%;

    .left-container {
        width: 40%;
        display: flex;
        flex-direction: column;

        .pieChart {
            height: 60%;
        }

        .dv-box-container {
            flex-grow: 1;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .dv-border-box {
                width: 40%;
                height: 60px;

                .dv-box {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;

                     .title {
                         color: @green
                     }

                     .value {
                         color: @yellow
                     }
                }
            }
        }
    }

    .right-container{
        flex-grow: 1;
    }
}
</style>
